<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Location</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Source"
              >
                <v-select
                  v-model="position"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="['Login Page', 'Forgot Password Page']"
                  placeholder="None"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Upload Banner</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Image"
              >
                <b-form-file
                  v-model="image"
                  placeholder="Select Banner"
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  @input="handleFileChange($event, 'image', 'banner')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="image" />
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import axios from '@/components/axios';
import Attachment from "../../../components/Attechment.vue";
import vSelect from "vue-select";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";

export default {
  components: {
    vSelect,
    Attachment,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },

  data() {
    return {
      image: [],
      accessToken: localStorage.getItem("accessToken"),
      position: "",
      check: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
    };
  },
  mounted() {
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.$router.push("/");
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getBanners();
  },
  methods: {
    async getBanners() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getbannerById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          const data = json.data.data;
          this.position = data[0].position;
          this.image = data[0].image;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleFileChange(e, name, type) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.image =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.check = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/setup");
    },
    submitForm(e) {
      const data = {
        position: this.position,
        image: this.image,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/banner/${this.$route.params.id}`
              : `${baseApi}/banner`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/setup/banner");
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>